/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/


.webmap {
    position: absolute;
    right: 0;
    left: 105px;
    top: 46px;
    bottom: 0;
}

/*.logo {*/
/*    position: absolute;*/
/*    left: 3px;*/
/*    top: 5px;*/
/*    width: 125px;*/
/*    height: 46px;*/
/*    z-index: 99;*/
/*}*/
.logoINRS {
    position: absolute;
    left: 3px;
    top: 5px;
    width: 96px;
    height: 181px;
    /*width: 206px;*/
    /*height: 120px;*/
}

.logoEnun {
    position: absolute;
    left: 2px;
    top: 195px;
    width: 100px;
    height: 12px;
}

/*.navStats {*/
/*    position: absolute;*/
/*    top: 170px;*/
/*}*/

.sliderstory {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    text-align: center;
    /*z-index: 99;*/
}

.slider {
    position: absolute;
    bottom: 8px;
    right: 5px;
    left: 110px
}

.debit {
    position: absolute;
    bottom: 30px;
    right: 230px;
    font-weight: bolder;
    text-align: center;
}

.buttongrp {
    position: absolute;
    bottom: 18px;
    left: 135px;
}

.logos {
    position: absolute;
    width: 106px;
    top: 0;
    left: 0;
}

.statsBlock {
    /*position: absolute;*/
    /*margin: 5px;*/
    /*border: 2px;*/
    /*background-color: rgb(23, 18, 123);*/
    /*border-radius: 10px;*/
}

.statsBlockContainer {
    position: absolute;
    /*display: flex;*/
    flex-direction: column;
    flex-wrap: wrap;
    width: 105px;
    top: 210px;
    bottom: 0;
    left: 2px;
    text-align: center;
    line-height: 100%;
}

.nstats {
    /*position: relative;*/
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: bolder;
}

.pstats {
    /*position: relative;*/
    text-align: center;
    color: red;
    font-size: 16px;
    font-weight: bolder;
}


#btnVisible {
    position: absolute;
    display: none;
    right: 60px;
    bottom: 60px;
    width: 45px;
    height: 80px;
    z-index: 99;
}


#btnToggle {
    position: absolute;
    right: 15px;
    bottom: 30px;
    width: 33px;
    height: 33px;
    z-index: 999;
}

#popup {
    /*width: 200px;*/
    font-size: 12px;
}

#layervisibility {
    color: white;
    background-color: black;
    width: 200px;

}

[data-title] {
    /*outline: red dotted 1px; !*optional styling*!*/
    /*font-size: 30px; !*optional styling*!*/
    position: relative;
    cursor: help;
}

[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    right: 50px;
    display: inline-block;
    /*padding: 3px 6px;*/
    /*border-radius: 2px;*/
    /*background: #000;*/
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
}

/*[data-title]:hover::after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  bottom: -10px;*/
/*  left: 8px;*/
/*  display: inline-block;*/
/*  color: #fff;*/
/*  border: 8px solid transparent;*/
/*  !*border-bottom: 8px solid #000;*!*/
/*}*/

#btnBuilding {
    color: red;
}

#btnStreet {
    color: red;
}

#btnBag {
    color: red;
}

@media (max-width: 450px) {
    .statsBlockContainer {
        display: none;
    }

    .logos {
        display: none;
    }

    .slider {
        left: 5px;
    }

    .webmap {
        left: 0;
    }
}

@media (max-height: 510px) {
    .statsBlockContainer {
        display: none;
    }

    .logos {
        display: none;
    }

    .slider {
        left: 0;
    }

    .webmap {
        left: 0;
    }
}

.logoMSP{
    width: 100px;
    position: absolute;
    bottom: 20px;
    left: 0;
}

.logoEC{
    width: 100px;
    position: absolute;
    bottom: 5px;
    left: 0;
}